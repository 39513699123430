import dynamic from 'next/dynamic'
import App, { AppProps, AppContext } from 'next/app'
import '../styles/globals.scss'
import { AppContextProvider } from '../components/context/AppContext'
import getConfig from 'next/config'

const Space = dynamic(() => import('./space/index'))

type MyAppProps = AppProps & {
  token: string
  origin: string
}

function MyApp({ Component, pageProps }: MyAppProps): JSX.Element {
  // If clientName is not set, render the Splash Page.
  const { clientName } = pageProps

  return (
    <AppContextProvider clientName={clientName}>
      {clientName ? <Component {...pageProps} /> : <Space {...pageProps} />}
    </AppContextProvider>
  )
}

MyApp.getInitialProps = async (appContext: AppContext) => {
  // calls page's `getInitialProps` and fills `appProps.pageProps`
  const initAppProps = await App.getInitialProps(appContext)
  // using public so we can rely on next/link to load new pages faster.
  const { publicRuntimeConfig } = getConfig()
  const clientName = publicRuntimeConfig.clientName

  const appProps = {
    ...initAppProps,
    pageProps: {
      ...initAppProps.pageProps,
      clientName,
    },
  }

  return { ...appProps }
}

export default MyApp
